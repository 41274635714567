import { Match, Switch } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { TextLink } from '@troon/ui';
import type { ApiResponse } from '@troon/api-client';

export type ProgramType = NonNullable<ApiResponse<'get', '/v0/visa/tokens/{token}'>['visaStatus']>['programType'];
export type RewardLevel = NonNullable<ApiResponse<'get', '/v0/visa/tokens/{token}'>['newRewardsLevel']>;

export const title: Record<ProgramType, string> = {
	VISA_SIG: 'Visa Signature®',
	VISA_INF: 'Visa Infinite®',
	VISA_CAD_INF: 'Visa Infinite®',
	VISA_CAD_INF_PRIV: 'Visa Infinite Privilege®',
};

export const logos: Record<ProgramType, string> = {
	VISA_INF:
		'https://images.ctfassets.net/rdsy7xf5c8dt/oq9u8ytZj41pYIiXV4tK7/d05c246487c999fb15a8bcdf83e11821/Visa_Infinite_Logo.svg',
	VISA_SIG:
		'https://images.ctfassets.net/rdsy7xf5c8dt/75FoHN16e7IeILGnTq1z3J/0d2ee6544305a8b1b7d435eb3ade6cd1/Visa_Signature_Logo.svg',
	VISA_CAD_INF:
		'https://images.ctfassets.net/rdsy7xf5c8dt/oq9u8ytZj41pYIiXV4tK7/d05c246487c999fb15a8bcdf83e11821/Visa_Infinite_Logo.svg',
	VISA_CAD_INF_PRIV:
		'https://images.ctfassets.net/rdsy7xf5c8dt/4N51IXCq77mWLykft0HfPd/bdd6d0b85072def56acc5ffb82f18424/Visa_Infinite_Privilege_Logo.svg',
};

export const slugToProgram: Record<string, ProgramType> = {
	'visa-signature': 'VISA_SIG',
	'visa-infinite': 'VISA_INF',
	'visa-infinite-ca': 'VISA_CAD_INF',
	'visa-infinite-privilege-ca': 'VISA_CAD_INF_PRIV',
};

export function VisaBenefits(props: { privateEligible?: boolean; program: ProgramType }) {
	return (
		<Switch>
			<Match when={props.program === 'VISA_SIG'}>
				<p>
					Your Visa Signature card entitles you to automatic Silver Status when you sign up for Troon Rewards®, for 10%
					saving on golf fees, merchandise and lessons at over 150 golf courses across the United States and world.
				</p>
			</Match>
			<Match when={props.program === 'VISA_INF'}>
				<p>
					Your United States Visa Infinite® card entitles you to automatic Platinum Status when you sign up for Troon
					Rewards®, 20% saving on golf fees, merchandise and lessons at over 150 golf courses across the United States
					and world.
				</p>

				<p>
					Your Visa Infinite card also gives access to over{' '}
					<Dynamic
						component={props.privateEligible ? TextLink : 'span'}
						href={props.privateEligible ? '/rewards/visa/request-a-tee-time' : undefined}
					>
						20 high-end, private Troon Privé golf clubs
					</Dynamic>
					. Play each club two times per year for $99 per player per round when you use your Visa Infinite card to book
					and bring up to three guests at the same rate.
				</p>
			</Match>
			<Match when={props.program === 'VISA_CAD_INF'}>
				<p>
					As a Visa Infinite® Canadian cardholder, you have access to two great benefits with Troon Golf, so you can
					experience new courses on your next getaway.
				</p>
			</Match>
			<Match when={props.program === 'VISA_CAD_INF_PRIV'}>
				<p>
					As a Visa Infinite Privilege® cardholder, you have access to two great benefits with Troon Golf, so you can
					experience new courses on your next getaway.
				</p>

				<p>
					Your Visa Infinite Privilege card also gives access to over{' '}
					<Dynamic
						component={props.privateEligible ? TextLink : 'span'}
						href={props.privateEligible ? '/rewards/visa/request-a-tee-time' : undefined}
					>
						20 high-end, private Troon Privé golf clubs
					</Dynamic>
					. Play each club two times per year for $99 per player per round when you use your Visa Infinite card to book
					and bring up to three guests at the same rate.
				</p>
			</Match>
		</Switch>
	);
}

export const rewardLevel: Record<RewardLevel, string> = {
	MEMBER: 'Member',
	SILVER: 'Silver',
	GOLD: 'Gold',
	PLATINUM: 'Platinum',
};
